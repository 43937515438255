import styled from 'styled-components'
import { useWeb3Context } from 'hooks/useWeb3Context'

const StyledConnectButtonPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledAddressBox = styled.div`
  font-family: "SF Pro";
  font-size: 1.7rem;
  fill: #141414;
  color: white;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #ca993b;
  padding: 10px 30px 10px 30px;
  margin-right: 10px;
`

const StyledConnectButton = styled.button`
  font-family: "SF Pro";
  font-size: 1.7rem;
  font-weight: 600;
  fill: #141414;
  color: #141414;
  background-color: #ca993b;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #ca993b;
  padding: 10px 30px 10px 30px;
  margin-right: 10px;
  cursor: pointer;
  :hover {
		background-color: #231F16;
    color: #ca993b;
		cursor: pointer;
	}
`

const WalletConnectButton = () => {
  const web3Context = useWeb3Context()

  const displayAddress =  `${web3Context?.account.substring(0, 4)}...${web3Context?.account.substring(web3Context?.account.length - 4)}`
  
  return (
    <>
      { web3Context?.account ?
        <StyledConnectButtonPanel>
          <StyledAddressBox>{displayAddress}</StyledAddressBox>
          <StyledConnectButton onClick={web3Context?.disconnect}>
            Disconnect
          </StyledConnectButton>
        </StyledConnectButtonPanel>        
        :
        <StyledConnectButton onClick={web3Context?.connectWallet}>Connect Wallet</StyledConnectButton>
      }
    </>
  )
}

export default WalletConnectButton