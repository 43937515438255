import styled from 'styled-components'
import WalletConnectButton from 'components/WalletConnectButton'

const StyledHeader = styled.div`
  display: flex;
  font-family: "SF Pro";
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 130px;
  }
  width: 100%;
  height: 110px;
  max-width: 1400px;
  align-items: center;
  justify-content: space-between;
`

const StyledLogo = styled.img`
  height: 80px;
`

const Header = () => {
  
  return (
    <StyledHeader>
      <a href="https://cryptoplatypus.io" target="_blank">
        <StyledLogo src="images/logo.png" alt="logo" />
      </a>
      <WalletConnectButton />
    </StyledHeader>
  )
}

export default Header