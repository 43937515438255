import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import './App.css'
import Mint from 'views/Mint'

const App = () => {
  return (
    <RefreshContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Mint /> } />
        </Routes>
      </BrowserRouter>
    </RefreshContextProvider>
    
  );
}

export default App;
