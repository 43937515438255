import styled from 'styled-components'
import Header from './components/Header'
import ActionContainer from './components/ActionContainer'

const StyledWrapper = styled.div`
  margin-bottom: 70px;
`

const StyledTitle = styled.div`
  font-family: "Adobe Garamond Pro Regular";
  font-size: 2.2rem;
  margin-top: 50px;
  margin-bottom: 30px;
`

const StyledContainers = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`

const StyledActionArea = styled.div`
  margin-top: 10px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  width: 100%;
`

const Mint = () => {
  return (
    <div className="App">
      <div className='App-Container'>
        <Header />
        <StyledWrapper>
          <StyledTitle style={{color:'#ca993b', fontSize: '40px'}}>Crypto Platypus NFT Minting Platform</StyledTitle>
          <StyledContainers>
            <StyledActionArea>
              <ActionContainer type="mint" />
            </StyledActionArea>
          </StyledContainers>
        </StyledWrapper>
      </div>
    </div>
  )
}

export default Mint