const contracts = {
  nft: { // NFT
    5: '0xD19e9A30f891306f7Ef52e50650B31A89DC45534',
    4: '0x5CF4890E08f50Dbc80A8A67FadbD5517922B2C37',
    1: '0x1fc6B3f9225b84111c7F5aEF0291b01A35b15731',
  },
  staking: { // NFT Staking
    5: '0xD19e9A30f891306f7Ef52e50650B31A89DC45534',
    4: '0x36000E261Ad3fD443bc557020130FE09f905d9cb',
    1: '0xcAfB5890A55cF9F315E4B865C190F1c3b5ab6854',
  },
  reward: { // Reward Token
    5: '0xD19e9A30f891306f7Ef52e50650B31A89DC45534',
    4: '0x39f2dB46AF00cdd849fd0014e20fa562669A9EcD',
    1: '0x48426C719AE9A29aF00553B2ac72173D89Cc93f6',
  },
  subReward: { // Reward Token
    5: '0xD19e9A30f891306f7Ef52e50650B31A89DC45534',
    4: '0x39f2dB46AF00cdd849fd0014e20fa562669A9EcD',
    1: '0xd81ac057df314c44c6a2c16fa844c097b756bc67',
  },
  multicall: {
    5: '0xD19e9A30f891306f7Ef52e50650B31A89DC45534',
    4: '0xa63B2b296A3c42C7EbFDC64a9B05f20A7361D5dd',
    1: '0x94f05e6869f26FC202797a68642d7500A9551288',
  },
}

export default contracts